// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function ProgressBar(Props) {
  var label = Props.label;
  var detailOpt = Props.detail;
  var progress = Props.progress;
  var detail = detailOpt !== undefined ? detailOpt : "";
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "w-full flex justify-between mb-1"
                }, React.createElement("span", {
                      className: "text-base font-medium"
                    }, label), React.createElement("span", {
                      className: "text-sm font-medium"
                    }, detail), React.createElement("span", {
                      className: "text-sm font-medium"
                    }, "" + String(progress) + "%")), React.createElement("div", {
                  className: "w-full bg-gray-200 rounded-full h-2.5"
                }, React.createElement("div", {
                      className: "bg-gray-600 h-2.5 rounded-full transition-all",
                      style: {
                        width: "" + String(progress) + "%"
                      }
                    })));
}

var make = ProgressBar;

export {
  make ,
}
/* react Not a pure module */
