// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as React from "react";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as ReactDom from "react-dom";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

ReactDom.render(React.createElement(React.StrictMode, {
          children: React.createElement(App.make, {})
        }), Js_option.getExn(Caml_option.nullable_to_opt(document.querySelector("#root"))));

export {
  
}
/*  Not a pure module */
