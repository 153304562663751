// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Spinner(Props) {
  return React.createElement("div", {
              className: "w-full h-full flex items-center justify-center"
            }, React.createElement("svg", {
                  className: "animate-spin h-20 w-20 text-gray-400",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("circle", {
                      className: "opacity-25",
                      cx: "12",
                      cy: "12",
                      r: "10",
                      stroke: "currentColor",
                      strokeWidth: "4"
                    }), React.createElement("path", {
                      className: "opacity-75",
                      d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
                      fill: "currentColor"
                    })));
}

var make = Spinner;

export {
  make ,
}
/* react Not a pure module */
