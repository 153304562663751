{
  "name": "webtorrent",
  "description": "Streaming torrent client",
  "version": "1.9.6",
  "author": {
    "name": "WebTorrent LLC",
    "email": "feross@webtorrent.io",
    "url": "https://webtorrent.io"
  },
  "browser": {
    "./lib/server.js": false,
    "./lib/conn-pool.js": false,
    "./lib/utp.js": false,
    "bittorrent-dht/client": false,
    "fs": false,
    "fs-chunk-store": "memory-chunk-store",
    "load-ip-set": false,
    "net": false,
    "os": false,
    "ut_pex": false
  },
  "browserify": {
    "transform": [
      "package-json-versionify"
    ]
  },
  "bugs": {
    "url": "https://github.com/webtorrent/webtorrent/issues"
  },
  "chromeapp": {
    "./lib/utp.js": false,
    "fs-chunk-store": "memory-chunk-store",
    "http": "@webtorrent/http-node",
    "load-ip-set": false,
    "net": "chrome-net",
    "os": false
  },
  "dependencies": {
    "@webtorrent/http-node": "^1.3.0",
    "addr-to-ip-port": "^1.5.4",
    "bitfield": "^4.1.0",
    "bittorrent-dht": "^10.0.6",
    "bittorrent-protocol": "^3.5.5",
    "cache-chunk-store": "^3.2.2",
    "chrome-net": "^3.3.4",
    "chunk-store-stream": "^4.3.0",
    "cpus": "^1.0.3",
    "create-torrent": "^5.0.9",
    "debug": "^4.3.4",
    "end-of-stream": "^1.4.4",
    "escape-html": "^1.0.3",
    "fast-blob-stream": "^1.1.1",
    "fs-chunk-store": "^3.0.1",
    "immediate-chunk-store": "^2.2.0",
    "join-async-iterator": "^1.1.1",
    "load-ip-set": "^2.2.1",
    "lt_donthave": "^1.0.1",
    "memory-chunk-store": "^1.3.5",
    "mime": "^3.0.0",
    "package-json-versionify": "^1.0.4",
    "parse-torrent": "^9.1.5",
    "pump": "^3.0.0",
    "queue-microtask": "^1.2.3",
    "random-iterate": "^1.0.1",
    "randombytes": "^2.1.0",
    "range-parser": "^1.2.1",
    "render-media": "^4.1.0",
    "run-parallel": "^1.2.0",
    "run-parallel-limit": "^1.1.0",
    "simple-concat": "^1.0.1",
    "simple-get": "^4.0.1",
    "simple-peer": "^9.11.1",
    "simple-sha1": "^3.1.0",
    "speed-limiter": "^1.0.2",
    "stream-with-known-length-to-buffer": "^1.0.4",
    "streamx": "^2.12.5",
    "throughput": "^1.0.1",
    "torrent-discovery": "^9.4.14",
    "torrent-piece": "^2.0.1",
    "unordered-array-remove": "^1.0.2",
    "ut_metadata": "^3.5.2",
    "ut_pex": "^3.0.2"
  },
  "devDependencies": {
    "@webtorrent/semantic-release-config": "1.0.8",
    "airtap": "4.0.4",
    "airtap-manual": "1.0.0",
    "airtap-sauce": "1.1.2",
    "babel-minify": "0.5.2",
    "bittorrent-tracker": "9.19.0",
    "browserify": "17.0.0",
    "disc": "1.3.3",
    "finalhandler": "1.2.0",
    "network-address": "1.1.2",
    "run-series": "1.1.9",
    "semantic-release": "19.0.5",
    "serve-static": "1.15.0",
    "standard": "*",
    "tape": "5.6.1",
    "webtorrent-fixtures": "1.7.5"
  },
  "optionalDependencies": {
    "utp-native": "^2.5.3"
  },
  "engines": {
    "node": ">=14"
  },
  "funding": [
    {
      "type": "github",
      "url": "https://github.com/sponsors/feross"
    },
    {
      "type": "patreon",
      "url": "https://www.patreon.com/feross"
    },
    {
      "type": "consulting",
      "url": "https://feross.org/support"
    }
  ],
  "homepage": "https://webtorrent.io",
  "keywords": [
    "bittorrent",
    "bittorrent client",
    "download",
    "mad science",
    "p2p",
    "peer-to-peer",
    "peers",
    "streaming",
    "swarm",
    "torrent",
    "web torrent",
    "webrtc",
    "webrtc data",
    "webtorrent"
  ],
  "license": "MIT",
  "main": "index.js",
  "repository": {
    "type": "git",
    "url": "git://github.com/webtorrent/webtorrent.git"
  },
  "scripts": {
    "build": "npm run build-js && npm run build-js-worker && npm run build-chromeapp",
    "build-chromeapp": "browserify --browser-field=chromeapp --standalone WebTorrent . | minify --mangle=false > webtorrent.chromeapp.js",
    "build-chromeapp-debug": "browserify --browser-field=chromeapp --standalone WebTorrent . > webtorrent.chromeapp.js",
    "build-js": "browserify --standalone WebTorrent . | minify --mangle=false > webtorrent.min.js",
    "build-js-worker": "browserify ./lib/worker.js | minify --mangle=false > sw.min.js",
    "build-js-debug": "browserify --standalone WebTorrent . > webtorrent.debug.js",
    "build-js-worker-debug": "browserify ./lib/worker.js > sw.debug.js",
    "prepublishOnly": "npm run build && npm run update-authors",
    "preversion": "npm run build && npm run update-authors",
    "size": "npm run size-js && npm run size-disc",
    "size-disc": "browserify --full-paths . | discify --open",
    "size-js": "npm run build && cat webtorrent.min.js | gzip | wc -c",
    "test": "standard && npm run test-node && npm run test-browser",
    "test-browser": "airtap --concurrency 1 -- test/*.js test/browser/*.js",
    "test-browser-local": "airtap --preset local -- test/*.js test/browser/*.js",
    "test-node": "tape test/*.js test/node/*.js",
    "update-authors": "./scripts/update-authors.sh"
  },
  "standard": {
    "ignore": [
      "webtorrent.min.js",
      "sw.min.js",
      "webtorrent.chromeapp.js"
    ]
  },
  "renovate": {
    "extends": [
      "github>webtorrent/renovate-config"
    ],
    "rangeStrategy": "bump"
  },
  "release": {
    "extends": "@webtorrent/semantic-release-config"
  }
}
