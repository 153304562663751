// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spinner from "./Spinner.bs.js";
import * as FileInput from "./FileInput.bs.js";
import * as CopyToClipboard from "./CopyToClipboard.bs.js";

function link(id) {
  var $$location = window.location;
  var host = $$location.host;
  var protocol = $$location.protocol;
  return "" + protocol + "//" + host + "/" + id + "";
}

function reducer(_state, action) {
  if (action) {
    return /* Seeding */{
            _0: action._0
          };
  } else {
    return /* Processing */1;
  }
}

function Seed(Props) {
  var client = Props.client;
  var match = React.useReducer(reducer, /* Waiting */0);
  var dispatch = match[1];
  var state = match[0];
  var onTorrent = React.useCallback((function (t) {
          var payload = {};
          payload["magnetUri"] = t.magnetURI;
          var body = JSON.stringify(payload);
          var request = new XMLHttpRequest();
          request.addEventListener("load", (function (param) {
                  var response = JSON.parse(request.response);
                  Curry._1(dispatch, /* SetId */{
                        _0: response.id
                      });
                }));
          request.open("POST", "/files");
          request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
          request.send(body);
        }), []);
  var onFiles = React.useCallback((function (fileList) {
          Curry._1(dispatch, /* SetProcessing */0);
          client.seed(fileList, onTorrent);
        }), []);
  return React.createElement("div", {
              className: "flex w-full h-full justify-center items-center"
            }, typeof state === "number" ? (
                state !== 0 ? React.createElement(Spinner.make, {}) : React.createElement(FileInput.make, {
                        onFiles: onFiles
                      })
              ) : React.createElement(CopyToClipboard.make, {
                    content: link(state._0)
                  }));
}

var make = Seed;

export {
  link ,
  reducer ,
  make ,
}
/* react Not a pure module */
