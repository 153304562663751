// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Button(Props) {
  var childrenOpt = Props.children;
  var onClickOpt = Props.onClick;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  return React.createElement("button", {
              className: "p-2 flex gap-2 items-center border border-gray rounded shadow bg-gray-50 hover:bg-gray-200 active:bg-gray-100",
              type: "button",
              onClick: onClick
            }, children);
}

var make = Button;

export {
  make ,
}
/* react Not a pure module */
