// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as ProgressBar from "./ProgressBar.bs.js";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";

function downloadBlobUrl(name, blobUrl) {
  var a = document.createElement("a");
  a.setAttribute("download", name);
  a.setAttribute("href", blobUrl);
  var root = document.querySelector("#root");
  if (!(root == null)) {
    root.appendChild(a);
    Js_option.getExn(Webapi__Dom__Element.asHtmlElement(a)).click();
    root.removeChild(a);
    return ;
  }
  
}

function formatBytes(suffixOpt, bytes) {
  var suffix = suffixOpt !== undefined ? suffixOpt : "";
  var bytes$1 = bytes;
  var $$default = [
    bytes$1,
    "B"
  ];
  var match = bytes$1 < 1024.0 ? $$default : (
      bytes$1 < 1e6 ? [
          bytes$1 / 1e3,
          "KB"
        ] : (
          bytes$1 < 1e9 ? [
              bytes$1 / 1e6,
              "MB"
            ] : (
              bytes$1 < 1e12 ? [
                  bytes$1 / 1e9,
                  "GB"
                ] : $$default
            )
        )
    );
  return "" + match[0].toFixed(2) + " " + match[1] + "" + suffix + "";
}

function Leech(Props) {
  var client = Props.client;
  var id = Props.id;
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setMagnetUri = match$1[1];
  var magnetURI = match$1[0];
  React.useEffect((function () {
          var request = new XMLHttpRequest();
          request.addEventListener("load", (function (param) {
                  if (request.status !== 200) {
                    return Curry._1(setState, (function (param) {
                                  return /* NotFound */1;
                                }));
                  }
                  var response = JSON.parse(request.response);
                  Curry._1(setMagnetUri, (function (param) {
                          return response.magnetUri;
                        }));
                }));
          request.open("GET", "/files/" + id + "");
          request.send("");
        }), []);
  React.useEffect((function () {
          if (magnetURI === undefined) {
            return ;
          }
          var torrent = client.add(magnetURI, undefined, undefined);
          torrent.on("download", (function (param) {
                  if (torrent.done) {
                    return Curry._1(setState, (function (param) {
                                  return /* Done */2;
                                }));
                  } else {
                    return Curry._1(setState, (function (param) {
                                  return /* Leeching */{
                                          _0: {
                                            downloadSpeed: torrent.downloadSpeed | 0,
                                            received: torrent.received,
                                            progress: client.progress * 100.0 | 0
                                          }
                                        };
                                }));
                  }
                }));
          torrent.on("done", (function (param) {
                  torrent.files[0].getBlobURL(function (param, blobUrl) {
                        downloadBlobUrl(torrent.name, blobUrl);
                      });
                }));
          return (function (param) {
                    client.remove(magnetURI);
                  });
        }), [magnetURI]);
  var tmp;
  if (typeof state === "number") {
    switch (state) {
      case /* Loading */0 :
          tmp = React.createElement("span", undefined, "Loading");
          break;
      case /* NotFound */1 :
          tmp = React.createElement("span", undefined, "Not Found");
          break;
      case /* Done */2 :
          tmp = React.createElement(ProgressBar.make, {
                label: "Done",
                progress: 100
              });
          break;
      
    }
  } else {
    var match$2 = state._0;
    tmp = React.createElement(ProgressBar.make, {
          label: "Downloading",
          detail: formatBytes("/s", match$2.downloadSpeed),
          progress: match$2.progress
        });
  }
  return React.createElement("div", {
              className: "p-2 sm:p-4 w-96 max-w-full h-96 max-h-full flex flex-col justify-center items-center"
            }, tmp);
}

var initialState = /* Loading */0;

var make = Leech;

export {
  downloadBlobUrl ,
  initialState ,
  formatBytes ,
  make ,
}
/* react Not a pure module */
