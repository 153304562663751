// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button from "./Button.bs.js";
import * as Outline from "@heroicons/react/outline";

var copy = ((content) => navigator.clipboard.writeText(content));

function CopyToClipboard(Props) {
  var content = Props.content;
  return React.createElement("div", {
              className: "flex flex-col w-auto p-2 sm:p-4"
            }, React.createElement(Button.make, {
                  children: null,
                  onClick: (function (param) {
                      copy(content);
                    })
                }, React.createElement(Outline.ClipboardIcon, {
                      className: "h-6"
                    }), "Copy Download Link"));
}

var make = CopyToClipboard;

export {
  copy ,
  make ,
}
/* react Not a pure module */
