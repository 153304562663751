// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Outline from "@heroicons/react/outline";

function FileInput(Props) {
  var onFiles = Props.onFiles;
  var match = React.useState(function () {
        return false;
      });
  var setDragOver = match[1];
  var fileInput = React.useRef(null);
  var onChange = function (e) {
    Curry._1(onFiles, e.target.files);
  };
  var onDragEnter = function (prim) {
    prim.preventDefault();
  };
  var onDragOver = function (e) {
    e.preventDefault();
    Curry._1(setDragOver, (function (param) {
            return true;
          }));
  };
  var onDragLeave = function (param) {
    Curry._1(setDragOver, (function (param) {
            return false;
          }));
  };
  var onDrop = function (e) {
    e.preventDefault();
    Curry._1(onFiles, e.dataTransfer.files);
  };
  var onClick = function (param) {
    var input = fileInput.current;
    if (!(input == null)) {
      input.click();
      return ;
    }
    
  };
  var $$class = match[0] ? "border-gray-400" : "";
  return React.createElement("div", {
              className: "select-none w-full h-full p-2 sm:p-4 flex justify-center items-center",
              onClick: onClick,
              onDragEnd: onDragLeave,
              onDragEnter: onDragEnter,
              onDragLeave: onDragLeave,
              onDragOver: onDragOver,
              onDrop: onDrop
            }, React.createElement("div", {
                  className: "border-4 border-dashed rounded p-2 sm:p-4 w-96 max-w-full h-96 max-h-full flex gap-2 justify-center items-center " + $$class + ""
                }, React.createElement(Outline.UploadIcon, {
                      className: "h-6"
                    }), "Drag a file here", React.createElement("input", {
                      ref: fileInput,
                      className: "hidden",
                      type: "file",
                      onChange: onChange
                    })));
}

var make = FileInput;

export {
  make ,
}
/* react Not a pure module */
