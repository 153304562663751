// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Seed from "./Seed.bs.js";
import * as Leech from "./Leech.bs.js";
import * as React from "react";
import Webtorrent from "webtorrent";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function App(Props) {
  var client = new Webtorrent();
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  if (match) {
    if (match.tl) {
      return React.createElement(Seed.make, {
                  client: client
                });
    } else {
      return React.createElement(Leech.make, {
                  client: client,
                  id: match.hd
                });
    }
  } else {
    return React.createElement(Seed.make, {
                client: client
              });
  }
}

var make = App;

export {
  make ,
}
/* Seed Not a pure module */
